import React, { ReactElement } from "react";
import {
  Layout as BaseLayout,
  LayoutProps as BaseLayoutProps,
  FluidSharpImageFile,
} from "@ymcansw-camping/common";
import { graphql, useStaticQuery } from "gatsby";
import ContactDetails from "./contact-details";
import "../styles/style.css";

export type LayoutProps = Pick<BaseLayoutProps, "meta" | "children">;

type Data = {
  readonly metaImage: FluidSharpImageFile;
};

function Layout({ meta, children }: LayoutProps): ReactElement<LayoutProps> {
  const data = useStaticQuery<Data>(graphql`
    {
      metaImage: file(relativePath: { eq: "img/camp-video-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            src
          }
        }
      }
    }
  `);

  return (
    <BaseLayout
      campName="Camp Yarramundi"
      subHeading="Outdoor Education & Camping"
      includeSafeEnvironment
      meta={meta}
      metaImage={data.metaImage.childImageSharp.fluid.src}
      headerMenuItems={[
        {
          to: "/",
          label: "Home",
        },
        {
          to: "/our-camp/",
          label: "Our Camp",
          items: [
            ["/our-camp/activities/", "Activities"],
            ["/our-camp/our-team/", "Our Team"],
            // ["/our-camp/virtual-tour/", "Virtual Tour"],
            ["/testimonials/", "Testimonials"],
            ["/schools/learning-framework/", "Values"],
          ],
        },
        {
          to: "/schools/",
          label: "Schools",
          items: [
            ["/schools/", "Our Programs"],
            ["/schools/residential/", "Residential based programs"],
          ],
        },
        {
          to: "/private-bookings/",
          label: "Private Bookings",
        },
        {
          to: "/contact/",
          label: "Contact",
        },
      ]}
      contactDetails={
        <>
          <h3>The Y Camp Yarramundi</h3>
          <ContactDetails />
        </>
      }
      footerColumns={[
        {
          to: "/our-camp/",
          label: "OUR CAMP",
          isBold: false,
          items: [
            ["/our-camp/", "About us"],
            ["/our-camp/activities/", "Activities"],
            ["/our-camp/our-team/", "Our Team"],
            ["/testimonials/", "Testimonials"],
          ],
        },
        {
          to: "/schools/",
          label: "SCHOOLS",
          isBold: false,
          items: [
            ["/schools/", "Our Programs"],
            ["/schools/learning-framework/", "Values"],
            ["/schools/residential/", "Residential Based Programs"],
            ["/contact/", "Make a Booking"],
          ],
        },
        {
          to: "/community/",
          label: "COMMUNITY",
          isBold: false,
          items: [
            ["/community/", "Community Programs"],
            ["/contact/", "Make a Booking"],
          ],
        },
        {
          to: "/private-bookings/",
          label: "PRIVATE BOOKINGS",
          isBold: false,
          items: [
            ["/testimonials/", "Testimonials"],
            ["/contact/", "Make a Booking"],
          ],
        },
        {
          isBold: true,
          items: [
            ["https://careers.ymcansw.org.au/", "CAREERS"],
            ["/contact/", "CONTACT"],
          ],
        },
      ]}
    >
      {children}
    </BaseLayout>
  );
}

export default Layout;
