import React from "react";
import fontawesome from "@fortawesome/fontawesome";
import {
  faEnvelope,
  faMapMarker,
  faPhone,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

fontawesome.library.add(faMapMarker, faPhone, faEnvelope);

export default function ContactDetails() {
  return (
    <ul className="icons-list">
      <li className="contact-details-address">
        <FontAwesomeIcon icon="map-marker" />
        <span>316 Springwood Rd, Yarramundi NSW 2753</span>
      </li>
      <li>
        <FontAwesomeIcon icon="phone" className="flip-horizontal" />
        <a href="tel:1800024097">1800 024 097</a>
      </li>
      <li>
        <FontAwesomeIcon icon="envelope" />
        <a href="mailto:admin.yarramundi@ymcansw.org.au">Email Us</a>
      </li>
    </ul>
  );
}
